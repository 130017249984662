<template>
  <div>
    <div class="text-right">
      <!-- add site button -->
      <v-btn
        data-cy="add_site"
        fab
        depressed
        outlined
        x-small
        color="hqsOrange"
        class="mb-5"
        @click="addSite"
      >
        <v-icon @click="addSite">mdi-plus</v-icon>
      </v-btn>
    </div>
    <v-data-table
      dense
      :headers="headers"
      :items="getSitesTable"
      v-model="selected"
      :item-class="highlightItem"
    >
      <template v-slot:top>
        <v-spacer></v-spacer>
        <!-- sites form dialog -->
        <v-dialog v-model="dialog" max-width="800px" persistent>
          <v-card>
            <v-toolbar flat class="hqsOrange--text text">
              <v-toolbar-title v-model="title"
                >{{ title }} Site</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-btn data-cy="close_sites_dialog" icon @click="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <SitesForm
                  @closeDialogOnEdit="closeDialogOnEdit"
                  v-bind:title="title"
                  @dialog="!dialog"
                ></SitesForm>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editSite(item)">mdi-pencil</v-icon>
        <v-icon small data-cy="delete_site" @click="askConfirmation(item)"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <!-- dialog for setting all magnetic field values at once (only for spins) -->
    <v-row v-if="$route.name.includes('spin-lattice')">
      <v-spacer />
      <MagneticFieldDialog />
      <InfoDialog info="allMagFields"></InfoDialog>
    </v-row>
    <ConfirmDialog
      :visible="showConfirmDialog"
      @close="showConfirmDialog = false"
      @remove="deleteSite"
    />
  </div>
</template>

<script>
const ConfirmDialog = () =>
  import(/* webpackChunkName: "dialogs" */ '../general/ConfirmDialog')
const MagneticFieldDialog = () =>
  import(/* webpackChunkName: "dialogs" */ './MagneticFieldDialog.vue')
const InfoDialog = () =>
  import(/* webpackChunkName: "dialogs" */ './InfoDialog.vue')
const SitesForm = () =>
  import(/* webpackChunkName: "dialogs" */ './SitesForm.vue')

import { createNamespacedHelpers } from 'vuex'

const { mapMutations, mapGetters, mapState } =
  createNamespacedHelpers('lattice')

export default {
  components: {
    MagneticFieldDialog,
    InfoDialog,
    SitesForm,
    ConfirmDialog,
  },

  data() {
    return {
      itemToDelete: {},
      showConfirmDialog: false,
      selected: [],
      title: '',
      dialog: false,
      required: [(v) => !!v || 'Required'],
      pagination: {},
      editedIndex: -1,
      valid_site: false,
      isSiteSubmitted: false,
    }
  },

  methods: {
    ...mapMutations([
      'prepareAddSite',
      'prepareEditSite',
      'resetSiteID',
      'delete',
      'setEditSiteIndex',
      'resetAddOneMore',
      'updatePlot',
    ]),

    closeDialogOnEdit(title) {
      if (title === 'Edit' || !this.addOneMore) {
        this.dialog = false
      }
    },

    highlightItem(item) {
      if (item.id === this.siteID) {
        return ['blue lighten-4']
      }
    },

    addSite() {
      if (this.latticeType != '' && this.latticeType != null) {
        this.title = 'Add'
        this.prepareAddSite()
      }
      this.dialog = true
    },

    editSite(item) {
      this.title = 'Edit'
      this.editedIndex = this.getSitesTable.indexOf(item)
      this.prepareEditSite(this.editedIndex)
      this.dialog = true
    },

    askConfirmation(item) {
      this.itemToDelete = item
      this.showConfirmDialog = true
    },

    deleteSite() {
      const index = this.getSitesTable.indexOf(this.itemToDelete)
      const type = 'site'
      this.showConfirmDialog = false
      this.delete([index, type])
    },

    close() {
      this.dialog = false
      this.resetSiteID()
      this.setEditSiteIndex('')
    },
  },

  computed: {
    ...mapGetters(['getSitesTable']),

    ...mapState({
      id: (state) => state.currentSite.id,
      addOneMore: (state) => state.addOneMore,
      latticeType: (state) => state.latticeType,
    }),

    headers() {
      let headers = [
        { text: 'ID', align: 'start', sortable: false, value: 'id' },
        { text: 'Name', align: 'start', sortable: false, value: 'name' },
        { text: 'Position', sortable: false, value: 'position' },
      ]
      if (this.latticeType.includes('spins')) {
        headers = headers.concat([
          { text: 'Magnetic Field', sortable: false, value: 'magneticField' },
        ])
      }
      if (this.latticeType.includes('spinless')) {
        headers = headers.concat([
          { text: 'Onsite Energy', sortable: false, value: 'e0' },
        ])
      }
      if (this.latticeType.includes('spinful')) {
        headers = headers.concat([
          /* currently not in use because qolossal does not need it */
          /* { text: 'Onsite U', sortable: false, value: 'onsite_U' }, */
          { text: 'Onsite BCS', sortable: false, value: 'onsite_BCS' },
        ])
      }
      headers.push({
        text: 'Actions',
        value: 'actions',
        sortable: false,
        align: 'end',
      })
      return headers
    },
  },

  created() {
    this.resetAddOneMore()
  },
}
</script>

<style lang="css" scoped></style>
